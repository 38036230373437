import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

const drawRelationshipText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  return _text;
};

const drawRelationshipDiamond = (context, width, height) => {
  let _diamond = context.two.makePath(0, -height / 2, -width / 2, 0, 0, height / 2, width / 2, 0);
  _diamond.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _diamond.stroke = colors.BLACK;
  return _diamond;
};

const drawWarnLine = context => {
  let _rect = context.two.makeRectangle(0, 12, 0, 2);
  _rect.noStroke();
  return _rect;
};

class RelationshipGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawRelationshipText();
    this.erDiamondContainer = this.context.two.makeGroup();
    this.erAttrs = this.context.two.makeGroup();
    this.erWarn = drawWarnLine(this.context);
    this.add(this.erAttrs, this.erDiamondContainer, this.erText, this.erWarn);
  }
  getAttrs() {
    return this.erAttrs;
  }
  getShape() {
    return { x: this.translation.x, y: this.translation.y, width: this.erDiamondWidth, height: this.erDiamondHeight };
  }
  setName(name) {
    this.erText.value = name;
    this.erText.size = name.length > 10 ? 16 : 17;
    const modelScene = this.context.diagram.scene.$model;
    const slope = 0.694 * Math.pow(0.9365, Math.max(3, name.length) / 3);
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    const paddedTextWidth = Math.max(120, textWidth + 20);
    this.erDiamondWidth = 28 / slope + paddedTextWidth;
    this.erDiamondHeight = slope * paddedTextWidth + 28;
    this.erDiamondContainer.remove(this.erDiamond);
    this.erDiamond = drawRelationshipDiamond(this.context, this.erDiamondWidth, this.erDiamondHeight);
    this.erDiamondContainer.add(this.erDiamond);
    this.erWarn.width = textWidth;
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.erDiamond.linewidth = selected ? 3 : 2;
    this.erWarn.height = selected ? 4 : 2;
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }
  setToBeTranslated(toBeTranslated) {
    this.erDiamond.stroke = toBeTranslated ? colors.RED : colors.BLACK;
  }
}

export const drawRelationship = (relationship, context) => {
  const scene = context.diagram.scene;
  let _relationship = context.diagram.$(relationship.getId());
  
  const warningsAllowed = !context.diagram.transparent;
  const relationshipErrors = relationship.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const relationshipWarnings = relationship.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  const translating = context.diagram.step == 'erTranslation';

  if(!_relationship) {
    _relationship = new RelationshipGroup(relationship.getId(), context);
    context.diagram.register(relationship.getId(), _relationship);
    scene.$modelInner.add(_relationship);
  }

  _relationship.setName(relationship.getName());
  _relationship.setPosition(relationship.getX(), relationship.getY());
  _relationship.setSelected(context.diagram.selectedItem?.getId() == relationship.getId());
  _relationship.setWarningsVisible(warningsAllowed && (relationshipErrors.length || relationshipWarnings.length), relationshipErrors.length);
  
  _relationship.setToBeTranslated(translating);
};