<template>
  <nav>
    <!--<Logo  display="block"/>-->
      <p class="logo">designER</p>
      <input  class="name" type="text" @change="$emit('input-proj', $event)" placeholder="ER project" :value="pN!='ER project' ? pN : ''"/>
      <div class="divdx">  
        <button class="my-btn1" @click="newDesign()">New project</button>
        <button class="my-btn1" @click="loadFromFile()">Open project</button>
        <!--<p @click="saveToFile()" v-bind:class="{ disabled: empty }">💾&ensp;Save to file</p> ?🛈ⓘ   -->       
        <button class="my-btn-help" @click="help()">?</button>
        <button id="ham-menu" class="my-btn" @click="toggleMenu()">☰</button>
        <div class="menu" v-if="showMenu">
          <p class="mobile-v" @click="newDesign()">📄&ensp;New project</p>
          <p class="mobile-v" @click="loadFromFile()">📤&ensp;Open project</p>
          <p @click="demo()">🎞️&ensp;Demo</p>
          <p @click="bugReports()">🐞&ensp;Bug reports</p>
          <p @click="about()">🎓&ensp;About</p>
          <p @click="termsOfUse()">📃&ensp;Terms of use</p>
        </div>
      </div>
      <!-- <div class="saved" v-if="draftSaved">
      draft saved
      </div>-->
      <!--  accept=".er"  to filter file in input-->
    <input ref="erInputFile" type="file" class="hide" tabindex="-1" accept=".er" @change="readFile($event.target.files[0]), $emit('read-name', $event.target.files[0].name.slice(0,-3))"  />
    <a ref="erOutputFile" class="hide" download=".er"></a>
  </nav>
</template>

<script>
import { ref, inject} from 'vue';
import { mainStore } from '../store/main';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

export default {
  name: 'NavigationBar',
  props: ['pName'],

  created() {
    window.addEventListener('keydown', this.onKeyDown);
  },

  methods:{
    onKeyDown(e) {  
      if( (e.ctrlKey && e.key == 'o') || (e.ctrlKey && e.key == 'O') ){
          e.preventDefault();
          this.loadFromFile();
        }
      if( (e.ctrlKey && e.key == 'N') || (e.ctrlKey && e.key == 'n') ){
          e.preventDefault();
          this.newDesign();
        }        
      },  
    },
  
  setup() {
    let currentURL = null;
    const erInputFile = ref(null);
    const erOutputFile = ref(null);    
    const empty = mainStore.$empty();
    const draftSaved = mainStore.$draftSaved();
    const showMenu = mainStore.$showMenu();
    const pN = inject('pN');
         
    function toggleMenu() {
      mainStore.toggleMenu();
      mainStore.erDesign.selectTool(null);
    }
    function newDesign() {
      //mainStore.resetState();
      analytics.event(analyticsEvents.APP_NEW_DESIGN);
      mainStore.openModal('newProject');    
    }
    function loadFromFile() {
      erInputFile.value.click();
    }
    function saveToFile() {
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
    }
    async function readFile(file) {
      //console.log(file.name.slice(0,-3));
      const data = await file.text();
      mainStore.setEditorData(JSON.parse(data).editorData);
      erInputFile.value.value = '';
      mainStore.loadFile(data);
    }
    function feedback() {
      mainStore.openModal('feedback');
    }
    function bugReports() {
      mainStore.openModal('bugReports');
    }
    function about() {
      mainStore.openModal('about');
    }
    function newProject() {
      mainStore.openModal('newProject');
    }
    function help() {
      mainStore.openModal('help');
    }
    function termsOfUse() {
      window.open('https://designerdb.io/terms-of-use/', '_blank')
    }
    function demo() {
      window.open('https://www.youtube.com/watch?v=hQhKaxOXik0', '_blank')
    }
    window.onclick = function(e) {
      if(showMenu.value && e.target.id != 'ham-menu')
        toggleMenu();
    };

    return {
      erInputFile,
      erOutputFile,
      empty,
      draftSaved,
      showMenu,
      newDesign,
      loadFromFile,
      saveToFile,
      readFile,
      feedback,
      bugReports,
      about,
      newProject,
      help,
      toggleMenu,
      pN,
      termsOfUse,
      demo
    };
  }
};
</script>
<style scoped>
.mobile-v{
    display: none;
  }
.logo {
  margin-top: 11.6px !important;
  left: 2px;
  letter-spacing: 3px !important;
}
.divdx{  
  margin-top: auto;
  margin-bottom: auto;
}
.align-name{
  position: inherit;
  text-align: center;
  margin: auto;
}
.name{
  position: relative;
  margin-top: auto !important;
  margin-bottom: auto !important;
  align-content: center;
  background-color: transparent;
  color: transparent;
  outline: white;
  border: 2px solid #323232; /* Bordo */
  line-height: 30px;
  width: 150px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.name, .logo{
  color: white;
  font-family: 'Commissioner', sans-serif;
  letter-spacing: 1.4px;
  /*line-height: 44px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  text-align: center;*/
  font-weight: 600;
  margin-bottom: auto;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: auto;
  display: flex;
  justify-content: center;
}
.my-btn{
  background-color: transparent !important;
  background: transparent !important;
  color: white;
  border-radius: 10px;
  margin: 4px;
  font-size: 0.80em !important;
  font-weight: 400;
  letter-spacing: 1px;
  height: 30px;
}
.my-btn1{
  background-color: transparent !important;
  background: transparent !important;
  color: white;
  border-radius: 10px;
  margin: 4px;
  font-size: 0.80em !important;
  font-weight: 400;
  letter-spacing: 1px;
  height: 30px;
}
.my-btn-help{
  background-color: white !important;
  background: white !important;
  color: black !important;
  font-size: 0.99em !important;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  height: auto;
  width: auto;
  border-radius: 50%;
  align-items: center;    
  border: transparent!important;;
}

/*box-shadow: 2px 2px 5px #38373c;*/

.my-btn:active {
  /*box-shadow: 2px 2px 5px #38373c;*/
  background-color: #38373c !important;
  background: #38373c !important;
}

nav {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: #191716;
}
#ham-menu{
  margin-right: 4px;
  font-size: 1em !important;
  margin-left: 0px;
}
/** with help button margin-left: 8px*/
.menu {
  position: absolute;
  top: 45px;
  right: 0px;
  width: auto;
  z-index: 3;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
  background: #f0f0f0;
}
.menu p {
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.menu p.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.menu p:not(.disabled):hover {
  background: #f2dea6;
}
.menu .sep {
  height: 1px;
  margin: 6px 0;
  border-bottom: 1px dashed #ccc;
}
p.build {
  pointer-events: none;
  color: #999;
  font-size: 0.65em;
}
.saved {
  position: absolute;
  cursor: default;
  user-select: none;
  right: 40px;
  margin: 15px 0;
  color: #f0f0f0;
  font-size: 12px;
}
@media all and (max-width: 720px) {
  .saved {
    right: 15px;
    font-size: 10px;
    margin: 18px 0;
  }
  .menu p {
    cursor: pointer;
    margin: 0;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 4px;
  }
  .my-btn1,
  .my-btn-help{
    display: none;
  }
  .mobile-v{
    display: block;
  }
}
</style>