<template>
  <div class="main-container">
    <transition @enter="designEnter" @leave="designLeave" :css="false">
      <div class="sub-container sub-container-design" v-if="step == 'erDesign'">
        <ERDesign />
      </div>
    </transition>
    <transition @enter="restructuringEnter" @leave="restructuringLeave" :css="false">
      <div class="sub-container sub-container-restructuring" v-if="step == 'erRestructuring'">
        <ERRestructuring />
      </div>
    </transition>
    <transition @enter="translationEnter" @leave="translationLeave" :css="false">
      <div class="sub-container sub-container-translation" v-if="step == 'erTranslation'">
        <ERTranslation />
      </div>
    </transition>
    <transition @enter="schemaEnter" @leave="schemaLeave" :css="false">
      <div class="sub-container sub-container-schema" v-if="step == 'schemaDesign'">
        <SchemaDesign />
      </div>
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap';

import ERDesign from './ERDesign';
import ERRestructuring from './ERRestructuring';
import ERTranslation from './ERTranslation';
import SchemaDesign from './SchemaDesign';

import { mainStore } from '../store/main';

export default {
  name: 'MainPanel',
  components: {
    ERDesign,
    ERRestructuring,
    ERTranslation,
    SchemaDesign
  },
  setup() {
    const step = mainStore.$step();

    function designEnter(el, done) {
      gsap.set(el, { zIndex: 3, yPercent: -100 });
      gsap.to(el, { duration: 0.75, yPercent: 0, ease: 'power2.out', onComplete: done });
    }
    function designLeave(el, done) {
      gsap.set(el, { zIndex: 1, yPercent: 0 });
      gsap.to(el, { duration: 0.75, yPercent: -100, ease: 'power2.out', onComplete: done });
    }
    function restructuringEnter(el, done) {
      gsap.set(el, { zIndex: 3, yPercent: 100 });
      gsap.to(el, { duration: 0.75, yPercent: 0, ease: 'power2.out', onComplete: done });
    }
    function restructuringLeave(el, done) {
      gsap.set(el, { zIndex: 1, yPercent: 0 });
      gsap.to(el, { duration: 0.75, yPercent: step.value == 'erDesign' ? 100 : -100, ease: 'power2.out', onComplete: done });
    }
    function translationEnter(el, done) {
      gsap.set(el, { zIndex: 3, yPercent: 100 });
      gsap.to(el, { duration: 0.75, yPercent: 0, ease: 'power2.out', onComplete: done });
    }
    function translationLeave(el, done) {
      gsap.set(el, { zIndex: 1, yPercent: 0 });
      gsap.to(el, { duration: 0.75, yPercent: step.value == 'erDesign' ? 100 : -100, ease: 'power2.out', onComplete: done });
    }
    function schemaEnter(el, done) {
      gsap.set(el, { zIndex: 3, yPercent: 100 });
      gsap.to(el, { duration: 0.75, yPercent: 0, ease: 'power2.out', onComplete: done });
    }
    function schemaLeave(el, done) {
      gsap.set(el, { zIndex: 1, yPercent: 0 });
      gsap.to(el, { duration: 0.75, yPercent: 100, ease: 'power2.out', onComplete: done });
    }

    return {
      step,
      designEnter,
      designLeave,
      restructuringEnter,
      restructuringLeave,
      translationEnter,
      translationLeave,
      schemaEnter,
      schemaLeave
    };
  }
};
</script>

<style scoped>
.main-container, .sub-container {
  height: 100%;
  background: #e0e2db;
}
.main-container {
  position: relative;
}
.sub-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>