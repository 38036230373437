<template>
<div class="my-editor-container" :style="{ width: containerWidth + 'px', height: containerHeight + 'px' }" ref="editorContainer">
  <div class="my-editor" >
    <editor @click="deselectItem($event)" spellcheck="false" @change="readEditor($event)" :value="readData()"/>
  </div>
</div>
</template>

<script>
import { quillEditor } from 'vue3-quill'// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mainStore } from '../store/main';
import { getContext } from '../lib/editor';

export default{
  name: 'QuillEditor',
  props: ['contextName'],
  components: {
    'editor': quillEditor,
  },
  data() {
    return {
      containerWidth: mainStore.getContainerWidth(),
      containerHeight: mainStore.getContainerHeight(),
      lastWidth: 500,
      lastHeight: 580,
      paddingBottom: 110,
    };
  },
  mounted() {
    const editorContainer = this.$refs.editorContainer;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;

        if(width == 0 || height ==0){
          mainStore.setContainerWidth(this.lastWidth);
          mainStore.setContainerHeight(this.lastHeight);
        }
        else {
          mainStore.setContainerWidth(width);
          mainStore.setContainerHeight(height);
          this.lastWidth = width;
          this.lastHeight = height;
        }
        document.documentElement.style.setProperty('--paddingBottom',(580- mainStore.getContainerHeight())/4 + 110 + 'px');
      }
    });
    resizeObserver.observe(editorContainer);
  },
  setup(props) {
    const store = mainStore.erDesign;
    const context = getContext(props.contextName);

    function readEditor(e){
      mainStore.setEditorData(e);
    }
    function readData(){  
      return mainStore.getEditorData();
    }
    function deselectItem(){
      context.callbacks.selectItem(null);
    }

  return {
    readEditor,
    readData,
    mainStore,
    store,
    deselectItem,
    context
    };
  }
};
</script>

<style scoped>
.my-editor{
  position: inherit;
  direction: ltr;
  height: 100%;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.my-editor-container{
  position: absolute;
  right: 0px;
  top: 60px;
  background: white;
  resize: both;
  direction: rtl;
  overflow-y: hidden;
}
.ql-container.ql-snow{
  border-bottom-color: white;
  padding-bottom: var(--paddingBottom);
}
</style>
