<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div><ToolboxButton text="Reset" icon="reset" @click="mainStore.resetTranslation()" /></div>
    <div v-if="selectedItem">
      <div class="sep"></div>
      <div class="submenu-container" v-if="supported.translating">
        <div><ToolboxButton text="Fix" icon="fix" :errorTooltip="(isEntity && !canEntityBeTranslated) ? 'This entity depends on other entities which have not been translated yet.' : 'All entities must be translated before proceeding with relationships.'" :disabled="(isEntity && !canEntityBeTranslated) || (!isEntity && modelContainsEntities)" :selected="showOneToManyTypeBRelationshipMenu || showOneToOneTypeBRelationshipMenu || showOneToOneTypeCRelationshipMenu" @click="store.translateItem()" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToManyTypeBRelationshipMenu">
            <div><ToolboxButton text="Into (0,1)" icon="into_left" @click="store.translateOneToManyTypeBRelationship('into_01')" /></div>
            <div><ToolboxButton text="Table" icon="new_table" @click="store.translateOneToManyTypeBRelationship('table')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToOneTypeBRelationshipMenu">
            <div><ToolboxButton text="Into A" icon="into_left" @click="store.translateOneToOneTypeBRelationship('into_first')" /></div>
            <div><ToolboxButton text="Into B" icon="into_right" @click="store.translateOneToOneTypeBRelationship('into_second')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showOneToOneTypeCRelationshipMenu">
            <div><ToolboxButton text="Into A" icon="into_left" @click="store.translateOneToOneTypeCRelationship('into_first')" /></div>
            <div><ToolboxButton text="Into B" icon="into_right" @click="store.translateOneToOneTypeCRelationship('into_second')" /></div>
            <div><ToolboxButton text="Table A" icon="new_table" @click="store.translateOneToOneTypeCRelationship('table_first')" /></div>
            <div><ToolboxButton text="Table B" icon="new_table" @click="store.translateOneToOneTypeCRelationship('table_second')" /></div>
          </div>
        </div>
      </div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
    </div>
    <div class="sep"></div>
    <div><ToolboxButton text="Design" icon="design" @click="mainStore.backToDesign()" /></div>
    <div><ToolboxButton text="Done" icon="done" errorTooltip="Translation must be complete before proceeding." :disabled="!canFinalize" @click="mainStore.moveToSchemaDesign()" /></div>
   <div class="submenu-container">
      <ToolboxButton text="Export" icon="export" :selected="showExportMenu" @click="store.toggleMenu('export')" />
      <div class="submenu-align">
        <div class="submenu" v-if="showExportMenu">
          <div><ToolboxButton text="PNG" icon="image" @click="saveAsPng()" /></div>
          <div><ToolboxButton text="Notes" icon="notes" @click="saveAsNotes()" errorTooltip="Notes must be non-empty in order for you to export them" :disabled="!mainStore.getEditorData() || mainStore.getEditorData().html == '' "/></div>
        </div>
      </div>
    </div>
    <ToolboxButton  ref="childComponent" text="Save"  icon="save"  @click="saveToFile()" errorTooltip="The model must be non-empty to be saved" @input-proj="inputProj($event)"/>
    <a ref="erOutputFile"  id="pName" class="hide" :download="setFileName() + '.er'"></a>
    <div ref="outputPngCanvas" class="hide"></div>
    <a ref="outputPngFile" id="pName" class="hide"  v-bind:pName="pName" :download="setFileName() + '.png'"></a>
    <a ref="outputNotesFile" class="hide" :download="setFileName() + '.txt'"></a>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';

import ToolboxButton from './ToolboxButton';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import Entity from '../lib/entity';
import { mainStore } from '../store/main';
import { getContext } from '../lib/editor';

export default {
  name: 'ERModelTranslateToolbox',
  components: {
    ToolboxButton
  },
  props: ['pName'],
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
  created() {
      window.addEventListener('keydown', this.onKeyDown);        
      window.addEventListener('keypress', this.onKeyPress);
      window.addEventListener('click', this.onClickOver);
      window.addEventListener('dblclick', this.onDoubleClick);
  },
  methods:{
    onClose() {
      this.$emit("dialog-closed");
      this.onClickOver(null);
    },
    onKeyDown(e){
      const step = mainStore.$step();
      if(step.value == 'erTranslation' && !this.showRenamingInput){
        if( (e.ctrlKey && e.key == 'p') || (e.ctrlKey  && e.key == 'P') && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveAsPng();
        }
        if( (e.ctrlKey && e.key == 's') || (e.ctrlKey  && e.key == 'S')  && !this.empty && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveToFile();
        }
        if( (e.ctrlKey && e.key == 'z')|| (e.ctrlKey  && e.key == 'Z') && !this.empty){
          e.preventDefault();
          mainStore.undo();
        }
        if( (e.ctrlKey && e.key == 'y') || (e.ctrlKey  && e.key == 'Y') && !this.empty){
          e.preventDefault();
          mainStore.redo();
        }
      }
    }
    
  },
  setup() {
    let currentURL = null;
    const erOutputFile = ref(null);

    const erTranslationStore = mainStore.erTranslation;
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erTranslationStore.$selectedItem();
    const showRenamingInput = erTranslationStore.$renamingInput();
    const showOneToManyTypeBRelationshipMenu = erTranslationStore.$oneToManyTypeBRelationshipMenu();
    const showOneToOneTypeBRelationshipMenu = erTranslationStore.$oneToOneTypeBRelationshipMenu();
    const showOneToOneTypeCRelationshipMenu = erTranslationStore.$oneToOneTypeCRelationshipMenu();
    const modelContainsEntities = erTranslationStore.$modelContainsEntities();
    const canFinalize = erTranslationStore.$canFinalize();
    
    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const isEntity = computed(() => selectedItem.value instanceof Entity);
    const canEntityBeTranslated = computed(() => selectedItem.value instanceof Entity && selectedItem.value.canBeTranslated());
    const outputPngCanvas = ref(null);
    const outputPngFile = ref(null);

    const pN = inject('pN');
    const showExportMenu = erTranslationStore.$exportMenu();
    const outputNotesFile = ref(null);
    function saveToFile() {
      if(this.getSelectedItem()==null){
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
      }
    }
    function setFileName(){
      if(pN.value.endsWith('_t'))
        return pN.value;
      else if(pN.value.endsWith('_c') || pN.value.endsWith('_r')) 
        return pN.value.slice(0, -2) + '_t';
      else 
        return pN.value + '_t';
    }     
    async function saveAsPng() {
      if(this.getSelectedItem()==null){
      erTranslationStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      const context = getContext('erTranslation');
      currentURL = window.URL.createObjectURL(new Blob([await context.exportPng(outputPngCanvas.value)]));
      outputPngFile.value.href = currentURL;
      outputPngFile.value.click();
      analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_PNG);
      }
    }
    function renameItem(e) {
      erTranslationStore.renameItem(e.target.value);
      e.target.value = erTranslationStore.getRenamingValue();
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erTranslationStore.toggleMenu();
    }
    function saveAsNotes() {
      if(mainStore.getEditorData()){
      erTranslationStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
       //replace(/(<([^>]+)>)/ig, '');      
      currentURL = window.URL.createObjectURL(new Blob([mainStore.getEditorData().replace(/(<([^>]+)>)/ig, '')]));
      outputNotesFile.value.href = currentURL;
      outputNotesFile.value.click();
      }
    }
    function getSelectedItem(){
        return erTranslationStore.getSelectedItem();
    }
    return {
      store: erTranslationStore,
      mainStore,
      canUndo,
      canRedo,
      selectedItem,
      showRenamingInput,
      showOneToManyTypeBRelationshipMenu,
      showOneToOneTypeBRelationshipMenu,
      showOneToOneTypeCRelationshipMenu,
      modelContainsEntities,
      canFinalize,
      supported,
      isEntity,
      canEntityBeTranslated,
      renameItem,
      onRenameKeydown,
      pN,
      saveToFile,
      erOutputFile,
      saveAsPng,
      outputPngFile,
      outputPngCanvas,
      showExportMenu,
      saveAsNotes,
      outputNotesFile,
      setFileName,
      getSelectedItem
    };
  }
};
</script>