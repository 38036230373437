export default {
  INTEGER: 'integer',
  TEXT: 'text',
  VARCHAR_100: 'varchar(100)',
  REAL: 'real',
  NUMERIC: 'numeric',
  DATE: 'date',
  DATETIME: 'datetime',
  BLOB: 'blob',
  JSON: 'json',
  BOOLEAN: 'boolean',
};