import { computed } from 'vue';

import { Store } from './store';
import Model from '../lib/model';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

export default class ERDesignStore extends Store {
  constructor(main) {
    super();
    this.mainStore = main;
  }
  __replace(state) {
    this.state.edited = state.edited;
    this.state.sceneResetToken = new Date().getTime();

    this.state.selectedItemId = null;
    this.state.selectedTool = null;
    this.state.oldRenamingValue = '';
    this.state.renamingValue = '';
    this.state.showRenamingInput = false;
    this.state.showRenamingRoleInput = false;
    this.state.showCardinalityMenu = false;
    this.state.showGeneralizationTypeMenu = false;
    this.state.showExportMenu = false;


    this.state.model = Model.fromObject(state.model || {});
  }
  data() {
    return {
      model: new Model(),
      edited: false,
      sceneResetToken: null,
      selectedItemId: null,
      selectedTool: null,
      oldRenamingValue: '',
      renamingValue: '',
      showRenamingInput: false,
      showRenamingRoleInput: false,
      showCardinalityMenu: false,
      showGeneralizationTypeMenu: false,
      showExportMenu: false,
    };
  }
  _resetMenus() {
    let updated = false;
    if((this.state.showRenamingInput || this.state.showRenamingRoleInput) && (this.state.oldRenamingValue != this.state.renamingValue))
      updated = true;

    this.state.selectedTool = null;
    this.state.showCardinalityMenu = false;
    this.state.showGeneralizationTypeMenu = false;
    this.state.showExportMenu = false;
    this.state.showNotes = false;

    if(updated) {
      this._itemsUpdated();
      const oldName = this.state.oldRenamingValue, newName = this.state.renamingValue;
      if(this.state.showRenamingInput) {
        const itemType = this.state.model.getItemById(this.state.selectedItemId).constructor.name;
        analytics.event(analyticsEvents.ER_ITEM_RENAME, { itemType, oldName, newName });
      } else if(this.state.showRenamingRoleInput) {
        analytics.event(analyticsEvents.ER_PARTICIPATION_ROLE_RENAME, { oldName, newName });
      }
    }
    
    this.state.showRenamingInput = false;
    this.state.showRenamingRoleInput = false;
  }
  _itemsUpdated(pushState = true) {
    this.state.edited = true;
    this.state.model.setERCode(this.state.model.toERCode());
    if(pushState)
      this.mainStore.pushState();
  }
  $sceneResetToken() {
    return computed(() => this.state.sceneResetToken);
  }
  $highlightedCode() {
    return computed(() => Prism.highlight(this.state.model.getERCode(), Prism.languages.er, 'er'));
  }
  $selectedItem() {
    return computed(() => this.getSelectedItem());
  }
  $selectedTool() {
    return computed(() => this.state.selectedTool);
  }
  $renamingInput() {
    return computed(() => this.state.showRenamingInput);
  }
  $renamingRoleInput() {
    return computed(() => this.state.showRenamingRoleInput);
  }
  $cardinalityMenu() {
    return computed(() => this.state.showCardinalityMenu);
  }
  $generalizationTypeMenu() {
    return computed(() => this.state.showGeneralizationTypeMenu);
  }
  $exportMenu() {
    return computed(() => this.state.showExportMenu);
  }
  $selectedItemWarnings() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getWarnings)
        return item.getWarnings().filter(w => !w.scope || w.scope == 'erDesign');
      return null;
    });
  }
  $selectedItemErrors() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getErrors)
        return item.getErrors().filter(e => !e.scope || e.scope == 'erDesign');
      return null;
    });
  }
  $canTranslate() {
    return computed(() => !this.isEmpty() && !this.state.model.hasErrors());
  }
  $canExport() {
    return computed(() => !this.isEmpty());
  }
  getCode() {
    return this.state.model.getERCode();
  }
  getSelectedItem() {
    return this.state.selectedItemId ? this.state.model.getItemById(this.state.selectedItemId) : null;
  }
  getRenamingValue() {
    return this.state.renamingValue;
  }
  isEmpty() {
    return this.state.model.isEmpty();
  }
  setCode(code) {
    this.state.model.setERCode(code);
    this._itemsUpdated();
  }
  addItem(x, y) {
    if(this.state.selectedTool == 'entity') {
      this.state.model.addEntity(null, x, y);
      analytics.event(analyticsEvents.ER_CREATE_ENTITY);
    } else if(this.state.selectedTool == 'relationship') {
      this.state.model.addRelationship(null, x, y);
      analytics.event(analyticsEvents.ER_CREATE_RELATIONSHIP);
    }
    this.state.selectedTool = null;
    this._itemsUpdated();
  }
  addParticipation(entityId) {
    try {
      this.state.model.addParticipation(entityId, null, this.state.selectedItemId);
      this._itemsUpdated();
      analytics.event(analyticsEvents.ER_CREATE_PARTICIPATION);
      return true;
    } catch(err) {
      return err.message;
    }
  }
  addGeneralization(entityId) {
    try {
      this.state.model.addGeneralization(entityId, this.state.selectedItemId);
      this._itemsUpdated();
      analytics.event(analyticsEvents.ER_CREATE_GENERALIZATION_CHILD);
      return true;
    } catch(err) {
      return err.message;
    }
  }
  selectItem(id) {
    const tool = this.state.selectedTool;
    this._resetMenus();

    if(tool == 'connect' && id != null) {
      return this.addParticipation(id);
    } else if(tool == 'parent' && id != null) {
      return this.addGeneralization(id);
    } else {
      this.state.selectedItemId = id;
      return true;
    }

  }
  moveItem(dx, dy) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      item.move(dx, dy);
  }
  finishedMovingItem() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      this._itemsUpdated();
  }
  renameItem(name) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().renaming)
      this.state.renamingValue = item.setName(name);
    this._itemsUpdated(false);
  }
  deleteItem() {
    const itemType = this.getSelectedItem().constructor.name;
    this.state.model.deleteItem(this.state.selectedItemId);
    analytics.event(analyticsEvents.ER_ITEM_DELETE, { itemType });
    this.state.selectedItemId = null;
    this._itemsUpdated();
  }
  addAttribute() {
    const parentItemType = this.getSelectedItem().constructor.name;
    this.state.model.addAttribute(null, this.state.selectedItemId);
    analytics.event(analyticsEvents.ER_CREATE_ATTRIBUTE, { parentItemType });
    this._resetMenus();
    this._itemsUpdated();
  }
  toggleIdentifier() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().identifier) {
      item.setIdentifier(!item.isIdentifier());
      analytics.event(analyticsEvents.ER_TOGGLE_IDENTIFIER, { identifier: item.isIdentifier() });
    }
    this._resetMenus();
    this._itemsUpdated();
  }
  toggleExternalIdentifier() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().externalIdentifier) {
      item.setExternalIdentifier(!item.isExternalIdentifier());
      analytics.event(analyticsEvents.ER_TOGGLE_EXTERNAL_IDENTIFIER, { itemType: item.constructor.name, externalIdentifier: item.isExternalIdentifier() });
    }
    this._resetMenus();
    this._itemsUpdated();
  }
  setCardinality(cardinality) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().cardinality) {
      item.setCardinality(cardinality);
      analytics.event(analyticsEvents.ER_ITEM_SET_CARDINALITY, { itemType: item.constructor.name, cardinality });
    }
    this._resetMenus();
    this._itemsUpdated();
  }
  setGeneralizationType(type) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().generalizationType) {
      item.setType(type);
      analytics.event(analyticsEvents.ER_GENERALIZATION_SET_TYPE, { generalizationType: type });
    }
    this._resetMenus();
    this._itemsUpdated();
  }
  setRole(role) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().role)
      this.state.renamingValue = item.setRole(role);
    this._itemsUpdated(false);
  }
  selectTool(tool) {
    this._resetMenus();
    this.state.selectedTool = tool;
  }
  toggleSelectedTool(tool) {
    const currentTool = this.state.selectedTool;
    this._resetMenus();

    if(currentTool == tool)
      this.state.selectedTool = null;
    else
      this.selectTool(tool);
  }
  toggleMenu(menu) {
    if(menu == 'renaming') {
      const showRenaming = this.state.showRenamingInput;
      this._resetMenus();
      this.state.showRenamingInput = !showRenaming;
      this.state.oldRenamingValue = this.getSelectedItem().getName();
      this.state.renamingValue = this.getSelectedItem().getName();
    } else if(menu == 'renamingRole') {
      const showRenamingRole = this.state.showRenamingRoleInput;
      this._resetMenus();
      this.state.showRenamingRoleInput = !showRenamingRole;
      this.state.oldRenamingValue = this.getSelectedItem().getRole();
      this.state.renamingValue = this.getSelectedItem().getRole();
    } else if(menu == 'cardinality') {
      const showCardinality = this.state.showCardinalityMenu;
      this._resetMenus();
      this.state.showCardinalityMenu = !showCardinality;
    } else if(menu == 'generalizationType') {
      const showGeneralizationType = this.state.showGeneralizationTypeMenu;
      this._resetMenus();
      this.state.showGeneralizationTypeMenu = !showGeneralizationType;
    } else if(menu == 'export') {
      const showExport = this.state.showExportMenu;
      this._resetMenus();
      this.state.showExportMenu = !showExport;
    }
     else
      this._resetMenus();
  }
}