import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import Attribute from '../../attribute';
import { QUADRANT, quadrantToAngle, rectQuadrant } from '../../utils/geometry';

const drawAttributeText = size => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = size;
  return _text;
};

const drawAttributeCircle = context => {
  let _circle = context.two.makeCircle(0, 0, 9);
  _circle.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _circle.stroke = colors.BLACK;
  return _circle;
};

const drawAttributeLine = (context, x, y) => {
  let _line = context.two.makeLine(x, y, 0, 0);
  _line.stroke = colors.BLACK;
  _line.linewidth = 2;
  return _line;
};

const drawAttributeRoundRect = context => {
  let _roundrect = context.two.makeRoundedRectangle(0, 0, 0, 32, 16);
  _roundrect.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _roundrect.stroke = colors.BLACK;
  return _roundrect;
};

const drawWarnLine = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 2);
  _rect.noStroke();
  return _rect;
};

class AttributeGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erBestAngle = null;
    this.erText = drawAttributeText(16);
    this.erCardinalityText = drawAttributeText(13);
    this.erCircle = drawAttributeCircle(this.context);
    this.erCompositeText = drawAttributeText(16);
    this.erRoundRect = drawAttributeRoundRect(this.context);
    this.erWarn = drawWarnLine(this.context);
    this.erDefaultLayoutContainer = this.context.two.makeGroup(this.erText, this.erCircle);
    this.erCompositeLayoutContainer = this.context.two.makeGroup(this.erRoundRect, this.erCompositeText);
    this.erLine = this.context.two.makeLine(0, 0, 0, 0);
    this.erLineContainer = this.context.two.makeGroup();
    this.erAttrs = this.context.two.makeGroup();
    this.add(this.erAttrs, this.erLineContainer, this.erDefaultLayoutContainer, this.erCompositeLayoutContainer, this.erCardinalityText, this.erWarn);
  }
  getAttrs() {
    return this.erAttrs;
  }
  getShape() {
    return this.erRoundRect;
  }
  setName(name) {
    this.erText.value = name;
    this.erCompositeText.value = name;
    const modelScene = this.context.diagram.scene.$model;
    const textWidth = this.erCompositeText.getBoundingClientRect().width / modelScene.scale;
    this.erRoundRect.width = textWidth + 15;
    this.erWarn.width = textWidth;
  }
  setCardinality(showCardinality, cardinality) {
    this.erCardinalityText.value = `(${cardinality.replace('_', ',')})`;
    this.erCardinalityText.opacity = showCardinality ? 1 : 0;
  }
  setIdentifier(identifier) {
    if(identifier)
      this.erCircle.fill = colors.BLACK;
    else if(this.context.diagram.transparent)
      this.erCircle.fill = colors.WHITE;
    else
      this.erCircle.fill = colors.BACKGROUND;
  }
  setComposite(composite) {
    this.erIsComposite = composite;
    this.erDefaultLayoutContainer.opacity = composite ? 0 : 1;
    this.erCompositeLayoutContainer.opacity = composite ? 1 : 0;
  }
  setPosition(x, y, entityRect) {
    this.erAttrs.translation.set(x, y);
    this.erCircle.translation.set(x, y);
    this.erRoundRect.translation.set(x, y);
    this.erCompositeText.translation.set(x, y + 2);
    this.erWarn.translation.set(x, y + 10);
    const quadrant = rectQuadrant(x, y, entityRect);
    this.erBestAngle = quadrantToAngle(quadrant);
    let cardX, cardY;
    switch(quadrant) {
      case QUADRANT.TOP:
        this.erText.alignment = 'center';
        this.erText.translation.set(x, y - 25);
        this.erCardinalityText.alignment = x > 0 ? 'left' : 'right';
        cardY = y + (this.erIsComposite ? 30 : 25);
        this.erCardinalityText.translation.set(cardY * (x / (y || 1)) + (x > 0 ? 10 : -10), cardY);
        break;
      case QUADRANT.RIGHT:
        this.erText.alignment = 'left';
        this.erText.translation.set(x + 20, y + 2);
        this.erCardinalityText.alignment = 'right';
        cardX = x - (this.erIsComposite ? this.erRoundRect.width / 2 + 6 : 18);
        this.erCardinalityText.translation.set(cardX, cardX * (y / (x || 1)) + (y > 0 ? 13 : -10));
        break;
      case QUADRANT.BOTTOM:
        this.erText.alignment = 'center';
        this.erText.translation.set(x, y + 30);
        this.erCardinalityText.alignment = x > 0 ? 'left' : 'right';
        cardY = y - (this.erIsComposite ? 25 : 20);
        this.erCardinalityText.translation.set(cardY * (x / (y || 1)) + (x > 0 ? 10 : -10), cardY);
        break;
      case QUADRANT.LEFT:
        this.erText.alignment = 'right';
        this.erText.translation.set(x - 20, y + 2);
        this.erCardinalityText.alignment = 'left';
        cardX = x + (this.erIsComposite ? this.erRoundRect.width / 2 + 6 : 18);
        this.erCardinalityText.translation.set(cardX, cardX * (y / (x || 1)) + (y > 0 ? 13 : -10));
        break;
    }
    this.erLineContainer.remove(this.erLine);
    this.erLine = drawAttributeLine(this.context, x, y);
    this.erLineContainer.add(this.erLine);
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.erCardinalityText.weight = selected ? 600 : 400;
    this.erCircle.linewidth = selected ? 3 : 2;
    this.erLine.linewidth = selected ? 3 : 2;
    this.erRoundRect.linewidth = selected ? 3 : 2;
    this.erWarn.height = selected ? 4 : 2;
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }
  setStep(restructuring, translation) {
    if(restructuring) {
      this.erCircle.stroke = this.erLine.stroke = this.erRoundRect.stroke = colors.RED;
      this.erText.fill = this.erCardinalityText.fill = colors.BLACK;
    } else if(translation) {
      this.erCircle.stroke = this.erLine.stroke = this.erRoundRect.stroke = colors.GRAY73;
      this.erText.fill = this.erCardinalityText.fill = colors.GRAY60;
      if(this.erCircle.fill == colors.BLACK)
        this.erCircle.fill = colors.GRAY73;
    } else {
      this.erCircle.stroke = this.erLine.stroke = this.erRoundRect.stroke = this.erText.fill = this.erCardinalityText.fill = colors.BLACK;
    }
  }
}

export const drawAttribute = (attribute, context) => {
  let _attribute = context.diagram.$(attribute.getId());
  const _parent = context.diagram.$(attribute.getParent().getId());

  const attributeParent = attribute.getParent();
  const partOfMultipleIdentifier = attributeParent.getAttributes && attributeParent.getAttributes().filter(a => a.isIdentifier()).length > 1;
  
  const warningsAllowed = !context.diagram.transparent;
  const attributeErrors = attribute.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const attributeWarnings = attribute.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  const restructuring = context.diagram.step == 'erRestructuring';
  const translating = context.diagram.step == 'erTranslation';

  if(!_attribute) {
    _attribute = new AttributeGroup(attribute.getId(), context);
    context.diagram.register(attribute.getId(), _attribute);
    _parent.getAttrs().add(_attribute);
  }

  _attribute.setName(attribute.getName());
  _attribute.setPosition(attribute.getX(), attribute.getY(), _parent.getShape());
  _attribute.setComposite(attribute.hasSubattributes());
  _attribute.setCardinality(attribute.getCardinality() != '1_1' && !(attributeParent instanceof Attribute), attribute.getCardinality());
  _attribute.setIdentifier(attribute.isIdentifier() && !partOfMultipleIdentifier);
  _attribute.setSelected(context.diagram.selectedItem?.getId() == attribute.getId());
  _attribute.setWarningsVisible(warningsAllowed && (attributeErrors.length || attributeWarnings.length), attributeErrors.length);

  _attribute.setStep(
    restructuring && attribute.getSupportedFunctionalities().restructuring,
    translating
  );
};