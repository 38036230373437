<template>
  <DiagramEditor contextName="erDesign" :store="store">
    <div class="notes" v-if="!showNotes">
      <div><ToolboxButton text="Notes" icon="into_left" :selected="showNotes" @click="mainStore.toggleNotesDataMenu()" class="notesButton"/></div>
    </div>
    <div class="notes" v-if="showNotes">
      <div><ToolboxButton text="Hide" icon="into_right" @click="mainStore.toggleNotesDataMenu()" class="notesButton" /></div>
      <QuillEditor contextName="erDesign"/>
    </div>
    <ERModelEditorToolbox />
    <ZoomButtons contextName="erDesign"/>
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
    <div class="info" v-if="['connect', 'parent'].includes(selectedTool)">
      🖍️
      <template v-if="selectedTool == 'connect'">Select an entity to participate in the relationship.</template>
      <template v-if="selectedTool == 'parent'">Select an entity to be the parent of the current entity.</template>
    </div>
  </DiagramEditor>
</template>

<script>
import { useToast } from 'vue-toastification';
import DiagramEditor from './DiagramEditor';
import ERModelEditorToolbox from './ERModelEditorToolbox';
import ErrorStatusBar from './ErrorStatusBar';
import ZoomButtons from './ZoomButtons.vue';
import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';
import ToolboxButton from './ToolboxButton.vue';
import QuillEditor from './QuillEditor.vue'
export default {
  name: 'ERModelEditor',
  components: {
    DiagramEditor,
    ERModelEditorToolbox,
    ErrorStatusBar,
    ZoomButtons,
    ToolboxButton,
    QuillEditor,
  },
  setup() {
    const toast = useToast();

    const store = mainStore.erDesign;
    const selectedTool = store.$selectedTool();
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    const showNotes = mainStore.$showNotesDataMenu();

    getContext('erDesign').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectTool: tool => store.selectTool(tool),
      addItem: (x, y) => store.addItem(x, y),
      selectItem: id => {
        const res = store.selectItem(id);
        if(res !== true)
          toast.error(res, { timeout: 5000 });
      },
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem(),
      deleteItem: () => store.deleteItem()
    });
        
    return {
      store,
      selectedTool,
      selectedItemWarnings,
      selectedItemErrors,
      showNotes,
      mainStore,
    };
  }
};
</script>

<style scoped>
.info {
  background: #cbcee4;
}
.notesButton {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 2em;
  text-align: center;
  visibility: visible; 
} 
@media all and (max-width: 720px) {
  .notes {
    display: none;
  }
}
</style>