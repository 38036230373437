export default {
  undo: '<path d="M31.953 13.203 11.953 33.203 10.234 35 11.953 36.797 31.953 56.797 35.547 53.203 19.844 37.5 52.5 37.5C59.385 37.5 65 43.115 65 50L65 67.5 70 67.5 70 50C70 40.391 62.109 32.5 52.5 32.5L19.844 32.5 35.547 16.797Z"></path>',
  redo: '<path d="M48.047 13.203 68.047 33.203 69.766 35 68.047 36.797 48.047 56.797 44.453 53.203 60.156 37.5 27.5 37.5C20.615 37.5 15 43.115 15 50L15 67.5 10 67.5 10 50C10 40.391 17.891 32.5 27.5 32.5L60.156 32.5 44.453 16.797Z"></path>',
  reset: '<path d="M40 11.563C24.321 11.563 11.563 24.321 11.563 40 11.563 55.679 24.321 68.438 40 68.438 55.679 68.438 68.438 55.679 68.438 40L64.063 40C64.063 53.314 53.314 64.063 40 64.063 26.686 64.063 15.938 53.314 15.938 40 15.938 26.686 26.686 15.938 40 15.938 47.772 15.938 54.658 19.62 59.055 25.32L53.125 31.25 68.438 31.25 68.438 15.938 62.166 22.209C56.949 15.722 48.953 11.563 40 11.563z"></path>',
  entity: '<path d="M5 18 L 5 62 75 62 75 18 10 18 10 23 70 23 70 57 10 57 10 18 Z"></path>',
  relationship: '<path d="M40 18 L 2 40 40 62 78 40 44.33 20.51 40 23.01 69.35 40 40 57 10.65 40 44.33 20.51 Z"></path>',
  attribute: '<path d="M10 37.5 L 10 42.5 52 42.5 52 37.5 Z"></path><circle cx="61" cy="40" r="9" stroke="#191716" stroke-width="4" fill="transparent" />',
  identifier: '<path d="M10 37.5 L 10 42.5 52 42.5 52 37.5 Z"></path><circle cx="61" cy="40" r="9" stroke="#191716" stroke-width="4" fill="#191716" />',
  card_0_1: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(0, 1)</text>',
  card_1_1: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(1, 1)</text>',
  card_0_N: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(0, N)</text>',
  card_1_N: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(1, N)</text>',
  gen_p_e: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(p, e)</text>',
  gen_p_o: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(p, o)</text>',
  gen_t_e: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(t, e)</text>',
  gen_t_o: '<text x="40" y="40" font-family="Commissioner" font-size="34" line-height="17" text-anchor="middle" dominant-baseline="middle" alignment-baseline="middle" font-weight="600">(t, o)</text>',
  rename: '<path d="M2.5 22.5 2.5 25 2.5 57.5 77.5 57.5 77.5 22.5 2.5 22.5zM7.5 27.5 72.5 27.5 72.5 52.5 7.5 52.5 7.5 27.5zM12.5 42.5 12.5 47.5 17.5 47.5 17.5 42.5 12.5 42.5zM22.5 42.5 22.5 47.5 27.5 47.5 27.5 42.5 22.5 42.5zM32.5 42.5 32.5 47.5 37.5 47.5 37.5 42.5 32.5 42.5z"></path>',
  delete: '<path d="M18.047 14.453 14.453 18.047 36.406 40 14.453 61.953 18.047 65.547 40 43.594 61.953 65.547 65.547 61.953 43.594 40 65.547 18.047 61.953 14.453 40 36.406Z"></path>',
  logical: '<path d="M15 12 L 15 68 65 68 65 12 20 12 20 17 60 17 60 63 20 63 20 12 Z M 20 27 L 20 32 60 32 60 27 Z M 25 40 L 25 43 55 43 55 40 Z M 25 49 L 25 52 55 52 55 49 Z"></path>',
  export: '<path d="M48.125 10.645 40 18.77 32.002 10.752 2.979 23.73 12.5 33.569 12.5 59.707 40 72.769 67.5 59.707 67.5 33.569 77.148 23.623 48.125 10.645zM49.224 16.616 68.574 25.269 63.203 30.806 43.608 22.231 49.224 16.616zM30.898 16.719 36.396 22.231 16.797 30.806 11.553 25.371 30.898 16.719zM40 26.113 57.397 33.726 40 41.333 22.603 33.726 40 26.113zM17.5 36.948 37.5 45.698 37.5 66.045 17.5 56.543 17.5 36.948zM62.5 36.948 62.5 56.543 42.5 66.045 42.5 45.698 62.5 36.948z"></path>',
  design: '<path d="M57.5 6.563 44.063 20 34.766 20C33.594 14.316 28.516 10 22.5 10 15.625 10 10 15.625 10 22.5 10 28.516 14.316 33.594 20 34.766L20 45 10 45 10 70 35 70 35 45 25 45 25 34.766C29.873 33.76 33.76 29.873 34.766 25L43.75 25 55 36.25 55 45.234C49.316 46.406 45 51.484 45 57.5 45 64.375 50.625 70 57.5 70 64.375 70 70 64.375 70 57.5 70 51.484 65.684 46.406 60 45.234L60 36.25 73.594 22.656ZM57.5 13.672 66.484 22.656 57.5 31.641 48.516 22.656ZM22.5 15C26.67 15 30 18.33 30 22.5 30 26.67 26.67 30 22.5 30 18.33 30 15 26.67 15 22.5 15 18.33 18.33 15 22.5 15ZM15 50 30 50 30 65 15 65ZM57.5 50C61.67 50 65 53.33 65 57.5 65 61.67 61.67 65 57.5 65 53.33 65 50 61.67 50 57.5 50 53.33 53.33 50 57.5 50ZM57.5 6.563 44.063 20 34.766 20C33.594 14.316 28.516 10 22.5 10 15.625 10 10 15.625 10 22.5 10 28.516 14.316 33.594 20 34.766L20 45 10 45 10 70 35 70 35 45 25 45 25 34.766C29.873 33.76 33.76 29.873 34.766 25L43.75 25 55 36.25 55 45.234C49.316 46.406 45 51.484 45 57.5 45 64.375 50.625 70 57.5 70 64.375 70 70 64.375 70 57.5 70 51.484 65.684 46.406 60 45.234L60 36.25 73.594 22.656ZM57.5 13.672 66.484 22.656 57.5 31.641 48.516 22.656ZM22.5 15C26.67 15 30 18.33 30 22.5 30 26.67 26.67 30 22.5 30 18.33 30 15 26.67 15 22.5 15 18.33 18.33 15 22.5 15ZM15 50 30 50 30 65 15 65ZM57.5 50C61.67 50 65 53.33 65 57.5 65 61.67 61.67 65 57.5 65 53.33 65 50 61.67 50 57.5 50 53.33 53.33 50 57.5 50Z"></path>',
  fix: '<path d="M13.125 6.875 11.719 8.203 8.203 11.719 6.875 13.125 7.891 14.766 13.125 23.516 13.828 24.766 21.172 24.766 31.172 34.688C22.236 43.662 10.869 55.068 10.469 55.469 6.553 59.385 6.543 65.801 10.547 69.531 14.453 73.389 20.82 73.486 24.531 69.531 24.561 69.502 24.58 69.482 24.609 69.453L40 53.984 55.469 69.531 55.703 69.688C59.629 73.389 65.869 73.438 69.531 69.531L69.531 69.453 69.609 69.453C73.438 65.537 73.477 59.17 69.531 55.469L69.453 55.391 56.406 42.422C65.186 41.602 72.061 34.189 72.188 25.234L72.266 25.234C72.275 25.186 72.266 25.127 72.266 25.078 72.266 25.049 72.266 25.029 72.266 25 72.51 22.109 71.885 19.346 70.391 16.953L68.672 14.297 57.031 25.938 53.516 22.266 65.391 10.391 61.953 8.984C59.941 8.125 57.617 7.5 55 7.5 45.391 7.5 37.5 15.391 37.5 25 37.5 26.045 37.725 26.953 37.891 27.891 36.797 28.984 35.977 29.883 34.688 31.172L24.766 21.25 24.766 13.828 23.516 13.125 14.766 7.891ZM55 12.5C55.352 12.5 55.596 12.705 55.938 12.734L46.484 22.188 48.203 23.984 55.234 31.25 56.953 33.047 66.875 23.125C66.924 23.701 67.334 24.121 67.266 24.766L67.266 25C67.266 31.885 61.65 37.5 54.766 37.5 53.848 37.5 52.734 37.285 51.484 37.031L50.156 36.797 49.219 37.734 21.016 66.016 20.938 66.016 20.938 66.094C19.16 68.037 16.055 68.086 13.984 66.016L13.984 65.938 13.906 65.938C11.963 64.16 11.914 61.055 13.984 58.984 14.932 58.037 33.281 39.531 42.266 30.547L43.281 29.531 42.891 28.125C42.686 27.314 42.5 25.918 42.5 25 42.5 18.115 48.115 12.5 55 12.5ZM13.906 13.125 19.766 16.719 19.766 19.219 19.219 19.766 16.719 19.766 13.125 13.906ZM50.469 43.516 66.016 58.984 66.016 59.063 66.094 59.063C68.037 60.84 68.086 63.945 66.016 66.016L65.938 66.016 65.938 66.094C64.16 68.037 61.055 68.086 58.984 66.016L43.516 50.469Z"></path>',
  gen_collapse_parent: '<path d="M30 12.5 30 32.5 38 32.5 38 38 13 38 13 47.5 5 47.5 5 67.5 25 67.5 25 47.5 17 47.5 17 42 38 42 38 47.5 30 47.5 30 67.5 50 67.5 50 47.5 42 47.5 42 42 63 42 63 47.5 55 47.5 55 67.5 75 67.5 75 47.5 67 47.5 67 38 42 38 42 32.5 50 32.5 50 12.5ZM9 51.5 21 51.5 21 63.5 9 63.5ZM34 51.5 46 51.5 46 63.5 34 63.5ZM59 51.5 71 51.5 71 63.5 59 63.5Z"></path>',
  gen_collapse_children: '<path d="M30 12.5 30 32.5 38 32.5 38 38 13 38 13 47.5 5 47.5 5 67.5 25 67.5 25 47.5 17 47.5 17 42 38 42 38 47.5 30 47.5 30 67.5 50 67.5 50 47.5 42 47.5 42 42 63 42 63 47.5 55 47.5 55 67.5 75 67.5 75 47.5 67 47.5 67 38 42 38 42 32.5 50 32.5 50 12.5ZM34 16.5 46 16.5 46 28.5 34 28.5Z"></path>',
  multi_attr_unique: '<path d="M40 15.625C26.559 15.625 15.625 26.559 15.625 40 15.625 53.441 26.559 64.375 40 64.375 53.441 64.375 64.375 53.441 64.375 40 64.375 26.559 53.441 15.625 40 15.625ZM40 19.688C51.243 19.688 60.313 28.757 60.313 40 60.313 51.243 51.243 60.313 40 60.313 28.757 60.313 19.688 51.243 19.688 40 19.688 28.757 28.757 19.688 40 19.688Z"></path>',
  multi_attr_shared: '<path d="M57.5 12.5C52.002 12.5 47.5 17.002 47.5 22.5 47.5 24.443 48.086 26.25 49.063 27.793L41.299 37.5 29.639 37.5C28.516 33.213 24.629 30 20 30 14.502 30 10 34.502 10 40 10 45.498 14.502 50 20 50 24.629 50 28.516 46.787 29.639 42.5L41.299 42.5 49.063 52.207C48.086 53.75 47.5 55.557 47.5 57.5 47.5 62.998 52.002 67.5 57.5 67.5 62.998 67.5 67.5 62.998 67.5 57.5 67.5 52.002 62.998 47.5 57.5 47.5 55.762 47.5 54.15 47.988 52.725 48.779L45.703 40 52.725 31.221C54.15 32.012 55.762 32.5 57.5 32.5 62.998 32.5 67.5 27.998 67.5 22.5 67.5 17.002 62.998 12.5 57.5 12.5ZM57.5 17.5C60.293 17.5 62.5 19.707 62.5 22.5 62.5 25.293 60.293 27.5 57.5 27.5 54.707 27.5 52.5 25.293 52.5 22.5 52.5 19.707 54.707 17.5 57.5 17.5ZM20 35C22.793 35 25 37.207 25 40 25 42.793 22.793 45 20 45 17.207 45 15 42.793 15 40 15 37.207 17.207 35 20 35ZM57.5 52.5C60.293 52.5 62.5 54.707 62.5 57.5 62.5 60.293 60.293 62.5 57.5 62.5 54.707 62.5 52.5 60.293 52.5 57.5 52.5 54.707 54.707 52.5 57.5 52.5Z"></path>',
  comp_attr_split: '<path d="M22.5 11.484 20.703 13.203 8.203 25.703 11.797 29.297 20 21.094 20 36.25C20 42.432 25.068 47.5 31.25 47.5 34.727 47.5 37.5 50.273 37.5 53.75L37.5 70 42.5 70 42.5 53.75C42.5 47.568 37.432 42.5 31.25 42.5 27.773 42.5 25 39.727 25 36.25L25 21.094 33.203 29.297 36.797 25.703 24.297 13.203ZM57.5 11.484 55.703 13.203 43.203 25.703 46.797 29.297 55 21.094 55 36.25C55 39.697 52.197 42.5 48.75 42.5 46.572 42.5 44.541 43.145 42.813 44.219 43.867 45.498 44.727 46.934 45.313 48.516 46.299 47.861 47.471 47.5 48.75 47.5 54.951 47.5 60 42.451 60 36.25L60 21.094 68.203 29.297 71.797 25.703 59.297 13.203Z"></path>',
  comp_attr_merge: '<path d="M40 11.484 38.203 13.203 25.703 25.703 29.297 29.297 37.5 21.094 37.5 47.5C37.5 54.434 31.934 60 25 60L12.5 60 12.5 65 25 65C34.639 65 42.5 57.139 42.5 47.5L42.5 21.094 50.703 29.297 54.297 25.703 41.797 13.203ZM44.922 54.844C44.287 56.563 43.457 58.135 42.422 59.609 45.605 62.91 50.059 65 55 65L67.5 65 67.5 60 55 60C50.85 60 47.197 57.959 44.922 54.844Z"></path>',
  into_left: '<path d="M15.938 13.75 15.938 66.25 59.688 66.25 59.688 46.563 55.313 46.563 55.313 61.875 20.313 61.875 20.313 18.125 55.313 18.125 55.313 33.438 59.688 33.438 59.688 13.75ZM41.094 29.063 31.66 38.428 30.156 40 31.66 41.572 41.094 50.938 44.17 47.861 38.496 42.188 64.063 42.188 64.063 37.813 38.496 37.813 44.17 32.139Z"></path>',
  into_right: '<path d="M64.062 66.25 64.062 13.75 20.312 13.75 20.312 33.437 24.687 33.437 24.687 18.125 59.687 18.125 59.687 61.875 24.687 61.875 24.687 46.562 20.312 46.562 20.312 66.25ZM38.906 50.937 48.34 41.572 49.844 40 48.34 38.428 38.906 29.062 35.83 32.139 41.504 37.812 15.937 37.812 15.937 42.187 41.504 42.187 35.83 47.861Z"></path>',
  new_table: '<path d="M16.281 16.281 16.281 63.719 63.719 63.719 63.719 16.281ZM20.594 20.594 59.406 20.594 59.406 59.406 20.594 59.406ZM27.063 27.063 27.063 31.375 52.938 31.375 52.938 27.063ZM27.063 37.844 27.063 42.156 52.938 42.156 52.938 37.844ZM27.063 48.625 27.063 52.938 40 52.938 40 48.625Z"></path>',
  done: '<path d="M40 11.563C24.252 11.563 11.563 24.252 11.563 40 11.563 55.748 24.252 68.438 40 68.438 55.748 68.438 68.438 55.748 68.438 40 68.438 36.941 68.027 33.89 66.934 31.045L63.379 34.531C63.815 36.283 64.063 38.035 64.063 40 64.063 53.347 53.347 64.063 40 64.063 26.653 64.063 15.938 53.347 15.938 40 15.938 26.653 26.653 15.938 40 15.938 46.563 15.938 52.458 18.552 56.611 22.705L59.688 19.629C54.655 14.596 47.656 11.563 40 11.563ZM64.678 20.928 40 45.605 30.635 36.24 27.49 39.385 38.428 50.322 40 51.826 41.572 50.322 67.822 24.072Z"></path>',
  primary: '<path d="M48.5 12.375C37.991 12.375 29.375 20.991 29.375 31.5 29.375 32.604 29.558 33.575 29.707 34.555L12.973 51.223 12.375 51.887 12.375 67.625 27.25 67.625 27.25 61.25 33.625 61.25 33.625 54.875 40 54.875 40 48.566C42.507 49.795 45.429 50.625 48.5 50.625 59.009 50.625 67.625 42.009 67.625 31.5 67.625 20.991 59.009 12.375 48.5 12.375ZM48.5 16.625C56.693 16.625 63.375 23.307 63.375 31.5 63.375 39.693 56.693 46.375 48.5 46.375 45.927 46.375 43.279 45.62 41.262 44.516L40.73 44.25 35.75 44.25 35.75 50.625 29.375 50.625 29.375 57 23 57 23 63.375 16.625 63.375 16.625 53.68 33.426 36.813 34.223 36.082 34.023 34.953C33.808 33.675 33.625 32.538 33.625 31.5 33.625 23.307 40.307 16.625 48.5 16.625ZM52.75 23C50.401 23 48.5 24.901 48.5 27.25 48.5 29.599 50.401 31.5 52.75 31.5 55.099 31.5 57 29.599 57 27.25 57 24.901 55.099 23 52.75 23Z"></path>',
  unique: '<path d="M40 13.75C26.73 13.75 15.938 24.542 15.938 37.813 15.938 47.161 21.329 55.227 29.131 59.209 29.199 57.645 29.567 56.227 30.225 54.902 24.312 51.501 20.313 45.11 20.313 37.813 20.313 26.96 29.148 18.125 40 18.125 50.852 18.125 59.688 26.96 59.688 37.813 59.688 45.11 55.688 51.501 49.775 54.902 50.433 56.235 50.809 57.645 50.869 59.209 58.671 55.227 64.063 47.161 64.063 37.813 64.063 24.542 53.27 13.75 40 13.75ZM40 53.125C36.403 53.125 33.438 56.09 33.438 59.688 33.438 63.285 36.403 66.25 40 66.25 43.597 66.25 46.563 63.285 46.563 59.688 46.563 56.09 43.597 53.125 40 53.125ZM40 57.5C41.23 57.5 42.188 58.457 42.188 59.688 42.188 60.918 41.23 61.875 40 61.875 38.77 61.875 37.813 60.918 37.813 59.688 37.813 58.457 38.77 57.5 40 57.5Z"></path>',
  type: '<path d="M40 12.827 38.682 13.633 32.236 17.954 16.563 17.954 16.563 67.173 63.438 67.173 63.438 17.954 47.764 17.954 41.318 13.633ZM40 18.467 45.713 22.275 46.299 22.642 58.75 22.642 58.75 62.485 21.25 62.485 21.25 22.642 33.701 22.642 34.287 22.275ZM25.938 32.017 25.938 36.704 30.625 36.704 30.625 32.017ZM35.313 32.017 35.313 36.704 54.063 36.704 54.063 32.017ZM25.938 41.392 25.938 46.079 30.625 46.079 30.625 41.392ZM35.313 41.392 35.313 46.079 54.063 46.079 54.063 41.392ZM25.938 50.767 25.938 55.454 30.625 55.454 30.625 50.767ZM35.313 50.767 35.313 55.454 54.063 55.454 54.063 50.767Z"></path>',
  reorder: '<path d="M40 9.58 38.428 11.084 20.928 28.584 24.072 31.729 37.813 17.988 37.813 62.012 24.072 48.271 20.928 51.416 38.428 68.916 40 70.42 41.572 68.916 59.072 51.416 55.928 48.271 42.188 62.012 42.188 17.988 55.928 31.729 59.072 28.584 41.572 11.084Z"></path>',
  reorder_move_up: '<path d="M40 24.004 38.428 25.508 11.084 52.852 14.229 55.996 40 30.225 65.771 55.996 68.916 52.852 41.572 25.508Z"></path>',
  reorder_move_down: '<path d="M14.229 24.004 11.084 27.148 38.428 54.492 40 55.996 41.572 54.492 68.916 27.148 65.771 24.004 40 49.775Z"></path>',
  image: '<path d="M9.938 16.875 9.938 63.125 70.063 63.125 70.063 16.875ZM14.563 21.5 65.438 21.5 65.438 52.863 47.443 34.869 45.781 33.279 44.119 34.869 38.844 40.145 30.1 31.4 28.438 29.811 26.775 31.4 14.563 43.613ZM55.031 23.813C51.87 23.813 49.25 26.432 49.25 29.594 49.25 32.755 51.87 35.375 55.031 35.375 58.193 35.375 60.813 32.755 60.813 29.594 60.813 26.432 58.193 23.813 55.031 23.813ZM55.031 28.438C55.7 28.438 56.188 28.925 56.188 29.594 56.188 30.262 55.7 30.75 55.031 30.75 54.363 30.75 53.875 30.262 53.875 29.594 53.875 28.925 54.363 28.438 55.031 28.438ZM28.438 36.387 35.52 43.469 20.488 58.5 14.563 58.5 14.563 50.262ZM45.781 39.855 64.426 58.5 27.137 58.5Z"></path>',
  code: '<path d="M12.25 14.563 12.25 65.438 67.75 65.438 67.75 14.563ZM16.875 19.188 63.125 19.188 63.125 23.813 16.875 23.813ZM16.875 28.438 63.125 28.438 63.125 60.813 16.875 60.813ZM40 33.063 35.375 56.188 40 56.188 44.625 33.063ZM28.871 34.002 21.934 43.252 20.922 44.625 21.934 45.998 28.871 55.248 32.629 52.502 26.703 44.625 32.629 36.748ZM51.129 34.002 47.371 36.748 53.297 44.625 47.371 52.502 51.129 55.248 58.066 45.998 59.078 44.625 58.066 43.252Z"></path>',
  save: '<svg width="82" height="72" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 19V5C3 3.89543 3.89543 3 5 3H16.1716C16.702 3 17.2107 3.21071 17.5858 3.58579L20.4142 6.41421C20.7893 6.78929 21 7.29799 21 7.82843V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19Z" stroke="currentColor" stroke-width="1.5"/> <path d="M8.6 9H15.4C15.7314 9 16 8.73137 16 8.4V3.6C16 3.26863 15.7314 3 15.4 3H8.6C8.26863 3 8 3.26863 8 3.6V8.4C8 8.73137 8.26863 9 8.6 9Z" stroke="currentColor" stroke-width="1.5"/> <path d="M6 13.6V21H18V13.6C18 13.2686 17.7314 13 17.4 13H6.6C6.26863 13 6 13.2686 6 13.6Z" stroke="currentColor" stroke-width="1.5"/> </svg>',
  notes: '<svg width="82" height="72" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.6375 9.04176L13.3875 14.2418C13.3075 14.3218 13.1876 14.3718 13.0676 14.3718H10.1075V11.3118C10.1075 11.1918 10.1575 11.0818 10.2375 11.0018L15.4376 5.84176" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.7076 11.9818V21.6618C18.7076 21.9018 18.5176 22.0918 18.2776 22.0918H2.84756C2.60756 22.0918 2.41754 21.9018 2.41754 21.6618V6.23176C2.41754 5.99176 2.60756 5.80176 2.84756 5.80176H12.4875" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.3863 2.90824L16.859 4.43558L20.0551 7.63167L21.5824 6.10433L18.3863 2.90824Z" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>',
};