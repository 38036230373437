<template>
  <DiagramEditor contextName="erTranslation" :translating="true" :store="store">
    <div class="notes" v-if="!showNotes">
      <div><ToolboxButton text="Notes" icon="into_left" :selected="showNotes" @click="mainStore.toggleNotesDataMenu()" class="notesButton"/></div>
    </div>
    <div class="notes" v-if="showNotes">
      <div><ToolboxButton text="Hide" icon="into_right" @click="mainStore.toggleNotesDataMenu()" class="notesButton" /></div>
      <QuillEditor contextName="erTranslation"/>
    </div>
    <ERModelTranslateToolbox />
    <ZoomButtons contextName="erTranslation"/>
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
  </DiagramEditor>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import ERModelTranslateToolbox from './ERModelTranslateToolbox';
import ErrorStatusBar from './ErrorStatusBar';
import ZoomButtons from './ZoomButtons.vue';
import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';
import ToolboxButton from './ToolboxButton.vue';
import QuillEditor from './QuillEditor.vue'

export default {
  name: 'ERModelTranslate',
  components: {
    DiagramEditor,
    ERModelTranslateToolbox,
    ErrorStatusBar,
    ZoomButtons,
    ToolboxButton,
    QuillEditor  
  },
  setup() {
    const store = mainStore.erTranslation;
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    const showNotes = mainStore.$showNotesDataMenu();

    getContext('erTranslation').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectItem: id => store.selectItem(id),
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem()
    });
      
    return {
      store,
      selectedItemWarnings,
      selectedItemErrors,
      showNotes,
      mainStore
    };
  }
};
</script>

<style scoped>
.notesButton {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 2em;
  text-align: center;
  visibility: visible; 
}
@media all and (max-width: 720px) {
  .notes {
    display: none;
  }
}
</style>