import Column from './column';
import ForeignKey from './foreignKey';

export default class Table {
  constructor(model, id, name, x, y, generatedFromMultivaluedAttribute) {
    this.__type = 'Table';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._mag = generatedFromMultivaluedAttribute;
  }
  static fromObject(model, obj) {
    return new Table(model, obj._id, obj._name, obj._x, obj._y, obj._mag);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getColumns() {
    let columns = this._model().getItemsWhere(i => i instanceof Column && i.getTable().getId() == this.getId());
    columns.sort((a, b) => a.getTableIndex() - b.getTableIndex());
    return columns;
  }
  getColumnByIndex(index) {
    return this._model().getItemWhere(i => i instanceof Column && i.getTable().getId() == this.getId() && i.getTableIndex() == index);
  }
  getForeignKeys() {
    return this._model().getItemsWhere(i => i instanceof ForeignKey && (i.getReferencingColumn().getTable().getId() == this.getId() || i.getReferencedColumn().getTable().getId() == this.getId()));
  }
  wasGeneratedFromMultivaluedAttribute() {
    return this._mag;
  }
  getSupportedFunctionalities() {
    return {
      moving: true,
      renaming: true
    };
  }
  getAllowedFunctionalities() {
    return {};
  }
  getErrors() {
    let errors = [];
    
    if(this._model().getItemsWhere(i => i instanceof Table && i.getName() == this.getName() && i.getId() != this.getId()).length)
      errors.push({ description: `There is a name conflict. Multiple tables are named "${this.getName()}".` });
    
    if(new Set(this.getColumns().map(a => a.getName())).size < this.getColumns().length)
      errors.push({ description: `The table has multiple columns with the same name.` });

    return errors;
  }
  getWarnings() {
    return [];
  }
  __beforeDelete() {
    for(let foreignKey of this.getForeignKeys())
      this._model().deleteItem(foreignKey.getId());

    for(let column of this.getColumns())
      this._model().deleteItem(column.getId());
  }
  setName(name) {
    name = name.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '').replace(/^\d+/g, '').toUpperCase();
    if(name.length)
      this._name = name;
    return name;
  }
  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  toSQLCode() {
    let code = `CREATE TABLE ${this.getName()} (\n    `;

    code += this.getColumns().map(c => c.toSQLCode()).join(',\n    ');
    code += ',\n    PRIMARY KEY (';
    code += this.getColumns().filter(c => c.isIdentifier()).map(c => c.getName()).join(', ');
    code += ')';

    for(let column of this.getColumns().filter(c => c.isForeignKey())) {
      const referencedColumn = column.getForeignKey().getReferencedColumn();
      code += `,\n    FOREIGN KEY (${column.getName()}) REFERENCES ${referencedColumn.getTable().getName()}(${referencedColumn.getName()})`;
      code += '\n    ON DELETE NO ACTION\n    ON UPDATE NO ACTION'

    }

    code += '\n);';
    return code;
  }
}