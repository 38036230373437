<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div><ToolboxButton text="Reset" icon="reset" @click="mainStore.resetRestructuring()" /></div>
    <div v-if="selectedItem">
      <div class="sep"></div>
      <div class="submenu-container" v-if="supported.restructuring">
        <div><ToolboxButton text="Fix" icon="fix" errorTooltip="We recommend you start with restructuring generalizations first." :disabled="!isGeneralization && modelContainsGeneralizations" :selected="showMultivalueAttributeMenu || showCompositeAttributeMenu || showGeneralizationMenu" @click="store.restructureItem()" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showMultivalueAttributeMenu">
            <div><ToolboxButton text="Unique" icon="multi_attr_unique" @click="store.restructureMultivalueAttribute('unique')" /></div>
            <div><ToolboxButton text="Shared" icon="multi_attr_shared" @click="store.restructureMultivalueAttribute('shared')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showCompositeAttributeMenu">
            <div><ToolboxButton text="Split" icon="comp_attr_split" @click="store.restructureCompositeAttribute('split')" /></div>
            <div><ToolboxButton text="Merge" icon="comp_attr_merge" @click="store.restructureCompositeAttribute('merge')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showGeneralizationMenu">
            <div><ToolboxButton text="Collapse" icon="gen_collapse_parent" @click="store.restructureGeneralization('collapse_into_parent')" /></div>
            <div><ToolboxButton text="Collapse" icon="gen_collapse_children" :errorTooltip="allowed.collapseIntoChildren.error" :disabled="!allowed.collapseIntoChildren.ok" @click="store.restructureGeneralization('collapse_into_children')" /></div>
            <div><ToolboxButton text="Subst." icon="relationship" @click="store.restructureGeneralization('substitute')" /></div>
          </div>
        </div>
      </div>
      <div class="rename-container" v-if="supported.role">
        <ToolboxButton text="Role" icon="rename" :selected="showRenamingRoleInput" @click="store.toggleMenu('renamingRole')" />
        <div class="rename-input-container" v-if="showRenamingRoleInput">
          <input type="text" spellcheck="false" placeholder="Participation role" v-focusselect="showRenamingRoleInput" @input="renameRole($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
    </div>
    <div class="sep"></div>
    <div><ToolboxButton text="Design" icon="design" @click="mainStore.backToDesign()" /></div>
    <div><ToolboxButton text="Translate" icon="logical" errorTooltip="Restructuring must be complete before proceeding with the translation." :disabled="!canTranslate" @click="mainStore.moveToTranslation()" /></div>
       <div class="submenu-container">
      <ToolboxButton text="Export" icon="export" :selected="showExportMenu" @click="store.toggleMenu('export')" />
      <div class="submenu-align">
        <div class="submenu" v-if="showExportMenu">
          <div><ToolboxButton text="PNG" icon="image" @click="saveAsPng()" /></div>
          <div><ToolboxButton text="Notes" icon="notes" @click="saveAsNotes()" errorTooltip="Notes must be non-empty in order for you to export them" :disabled="!mainStore.getEditorData() || mainStore.getEditorData().html == '' "/></div>
        </div>
      </div>
    </div>
    <ToolboxButton  ref="childComponent" text="Save"  icon="save"  @click="saveToFile()" errorTooltip="The model must be non-empty to be saved" @input-proj="inputProj($event)"/>
    <a ref="erOutputFile"  id="pName" class="hide" :download="setFileName() + '.er'"></a>
    <a ref="outputPngFile" id="pName" class="hide"  v-bind:pName="pName" :download="setFileName() + '.png'"></a>
    <div ref="outputPngCanvas" class="hide"></div>
    <a ref="outputNotesFile" class="hide" :download="setFileName() + '.txt'"></a>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';

import ToolboxButton from './ToolboxButton';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import Generalization from '../lib/generalization';
import { mainStore } from '../store/main';
import { getContext } from '../lib/editor';

export default {
  name: 'ERModelRestructToolbox',
  components: {
    ToolboxButton
  },
  props: ['pName'],
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
  created() {
      window.addEventListener('keydown', this.onKeyDown);        
      window.addEventListener('keypress', this.onKeyPress);
      window.addEventListener('click', this.onClickOver);
      window.addEventListener('dblclick', this.onDoubleClick);
  },
  methods:{
    onKeyDown(e){
      const step = mainStore.$step();
      if(step.value == 'erRestructuring' && !this.showRenamingInput){
      if( (e.ctrlKey && e.key == 'p') || (e.ctrlKey  && e.key == 'P') && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveAsPng();
        }
      if( (e.ctrlKey && e.key == 's') || (e.ctrlKey  && e.key == 'S') && !this.empty && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveToFile();
        }
      if( (e.ctrlKey && e.key == 'z') || (e.ctrlKey  && e.key == 'Z') && !this.empty){
        e.preventDefault();
        mainStore.undo();
      }
        if( (e.ctrlKey && e.key == 'y') || (e.ctrlKey  && e.key == 'y') && !this.empty){
        e.preventDefault();
        mainStore.redo();
      }
      }
    } 
  },
  setup() {
    let currentURL = null;
    const erOutputFile = ref(null);

    const erRestructuringStore = mainStore.erRestructuring;
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erRestructuringStore.$selectedItem();
    const showRenamingInput = erRestructuringStore.$renamingInput();
    const showRenamingRoleInput = erRestructuringStore.$renamingRoleInput();
    const showMultivalueAttributeMenu = erRestructuringStore.$multivalueAttributeMenu();
    const showCompositeAttributeMenu = erRestructuringStore.$compositeAttributeMenu();
    const showGeneralizationMenu = erRestructuringStore.$generalizationMenu();
    const modelContainsGeneralizations = erRestructuringStore.$modelContainsGeneralizations();
    const canTranslate = erRestructuringStore.$canTranslate();

    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const allowed = computed(() => selectedItem.value.getAllowedFunctionalities());
    const isGeneralization = computed(() => selectedItem.value instanceof Generalization);
    const outputPngCanvas = ref(null);
    const outputPngFile = ref(null);
    const pN = inject('pN');
    const outputNotesFile = ref(null);
    const showExportMenu = erRestructuringStore.$exportMenu();

    function setFileName(){
      if(pN.value.endsWith('_r'))
        return pN.value;
      else if(pN.value.endsWith('_c') || pN.value.endsWith('_t')) 
        return pN.value.slice(0, -2) + '_r';
      else 
        return pN.value + '_r';
    }       
    function saveToFile() {
      if(this.getSelectedItem()==null){
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
      }
    }
    async function saveAsPng() {
      if(this.getSelectedItem()==null){
      erRestructuringStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      const context = getContext('erRestructuring');
      currentURL = window.URL.createObjectURL(new Blob([await context.exportPng(outputPngCanvas.value)]));
      outputPngFile.value.href = currentURL;
      outputPngFile.value.click();
      analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_PNG);
      }
    }
    function renameItem(e) {
      erRestructuringStore.renameItem(e.target.value);
      e.target.value = erRestructuringStore.getRenamingValue();
    }
    function renameRole(e) {
      erRestructuringStore.setRole(e.target.value);
      e.target.value = erRestructuringStore.getRenamingValue();
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erRestructuringStore.toggleMenu();
    }
        function saveAsNotes() {
      if(mainStore.getEditorData()){
      erRestructuringStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
       //replace(/(<([^>]+)>)/ig, '');      
      currentURL = window.URL.createObjectURL(new Blob([mainStore.getEditorData().replace(/(<([^>]+)>)/ig, '')]));
      outputNotesFile.value.href = currentURL;
      outputNotesFile.value.click();
      }
    }
    function getSelectedItem(){
        return erRestructuringStore.getSelectedItem();
    }

    return {
      store: erRestructuringStore,
      mainStore,
      canUndo,
      canRedo,
      selectedItem,
      showRenamingInput,
      showRenamingRoleInput,
      showMultivalueAttributeMenu,
      showCompositeAttributeMenu,
      showGeneralizationMenu,
      modelContainsGeneralizations,
      canTranslate,
      supported,
      allowed,
      isGeneralization,
      renameItem,
      renameRole,
      onRenameKeydown,
      pN,
      saveToFile,
      erOutputFile,
      saveAsPng,
      outputPngFile,
      outputPngCanvas,
      showExportMenu,
      saveAsNotes,
      outputNotesFile,
      setFileName,
      getSelectedItem
    };
  }
};
</script>