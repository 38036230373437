import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';

const drawEntityOverlay = context => {
  let _text = new DiagramText('ENTITY');
  _text.family = 'Commissioner';
  _text.fill = colors.GRAY60;
  _text.size = 21;
  _text.weight = 400;

  let _rect = context.two.makeRectangle(0, 0, Math.max(180, _text.getBoundingClientRect().width + 100), 100);
  _rect.fill = colors.BACKGROUND;
  _rect.stroke = colors.GRAY73;
  _rect.linewidth = 2;

  let _entityOverlay = context.two.makeGroup(_rect, _text);
  return _entityOverlay;
};

const drawRelationshipOverlay = context => {
  let _text = new DiagramText('RELATIONSHIP');
  _text.family = 'Commissioner';
  _text.fill = colors.GRAY60;
  _text.size = 16;
  _text.weight = 400;

  let _diamond = context.two.makePath(0, -50, -94, 0, 0, 50, 94, 0);
  _diamond.fill = colors.BACKGROUND;
  _diamond.stroke = colors.GRAY73;
  _diamond.linewidth = 2;

  let _relationshipOverlay = context.two.makeGroup(_diamond, _text);
  return _relationshipOverlay;
};

class OverlayGroup extends DiagramGroup {
  constructor(context) {
    super(null, context);
    this.erEntity = drawEntityOverlay(context);
    this.erRelationship = drawRelationshipOverlay(context);
    this.add(this.erEntity, this.erRelationship);
  }
  showOverlay(tool) {
    this.erEntity.opacity = tool == 'entity' ? 0.5 : 0;
    this.erRelationship.opacity = tool == 'relationship' ? 0.5 : 0;
  }
}

export const drawOverlays = context => {
  const scene = context.diagram.scene;
  let _overlays = scene.$overlays;

  if(!_overlays) {
    _overlays = new OverlayGroup(context);
    scene.$overlays = _overlays;

    context.two.makeGroup(_overlays);
  }
  
  scene.$overlays.scale = context.transform.scale;

  _overlays.setPosition(-10000, -10000);
  _overlays.showOverlay(context.diagram.selectedTool);

  return _overlays;
};

