export default {
  /* generic */
  APP_STARTUP: 1001,
  APP_RESTORE_LOCAL_DESIGN: 1011,
  APP_NEW_DESIGN: 1101,
  APP_LOAD_DESIGN: 1102,
  APP_SAVE_DESIGN: 1103,
  APP_FEEDBACK: 1501,
  /* er design */
  ER_CREATE_ENTITY: 2111,
  ER_CREATE_RELATIONSHIP: 2121,
  ER_CREATE_ATTRIBUTE: 2131,
  ER_CREATE_PARTICIPATION: 2151,
  ER_CREATE_GENERALIZATION_CHILD: 2181,
  ER_TOGGLE_IDENTIFIER: 2301,
  ER_TOGGLE_EXTERNAL_IDENTIFIER: 2302,
  ER_GENERALIZATION_SET_TYPE: 2601,
  ER_PARTICIPATION_ROLE_RENAME: 2801,
  ER_ITEM_RENAME: 2901,
  ER_ITEM_SET_CARDINALITY: 2921,
  ER_ITEM_DELETE: 2991,
  /* restructuring */
  RESTR_GENERALIZATION_COLLAPSE_INTO_PARENT: 3101,
  RESTR_GENERALIZATION_COLLAPSE_INTO_CHILDREN: 3102,
  RESTR_GENERALIZATION_SUBSTITUTE: 3103,
  RESTR_COMPOSITE_SPLIT: 3201,
  RESTR_COMPOSITE_MERGE: 3202,
  RESTR_MULTIVALUED_UNIQUE: 3301,
  RESTR_MULTIVALUED_SHARED: 3302,
  RESTR_PARTICIPATION_RENAME_ROLE: 3801,
  RESTR_ITEM_RENAME: 3901,
  /* translation */
  TRANSL_INTID_ENTITY: 4101,
  TRANSL_EXTID_ENTITY: 4102,
  TRANSL_TERNARY_RELATIONSHIP: 4201,
  TRANSL_MANYTOMANY_RELATIONSHIP: 4301,
  TRANSL_ONETOMANY_RELATIONSHIP: 4401,
  TRANSL_ONETOONE_RELATIONSHIP: 4501,
  TRANSL_ITEM_RENAME: 4901,
  /* schema editing */
  SCHEMA_ASSIGN_DATA_TYPE: 5101,
  SCHEMA_MOVE_COLUMN_UP: 5201,
  SCHEMA_MOVE_COLUMN_DOWN: 5202,
  SCHEMA_TOGGLE_UNIQUE_CONSTRAINT: 5301,
  SCHEMA_ITEM_RENAME: 5901,
  /* undo/redo/reset */
  UNDO: 9001,
  REDO: 9002,
  RESET_RESTRUCTURING: 9051,
  RESET_TRANSLATION: 9052,
  /* design steps */
  STEP_ER_DESIGN: 9101,
  STEP_RESTRUCTURING: 9102,
  STEP_TRANSLATION: 9103,
  STEP_SCHEMA_EDITING: 9104,
  /* export */
  ER_SCHEMA_EXPORT_PNG: 9201,
  ER_SCHEMA_EXPORT_TXT: 9202,
  LOGICAL_SCHEMA_EXPORT_PNG: 9301,
  LOGICAL_SCHEMA_EXPORT_SQL: 9302
};