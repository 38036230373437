export default {
  core: {
    historySize: 20,
    savePeriodSeconds: 2
  },
  erDesign: {
    minScale: 0.4,
    maxScale: 3
  },
  pngExport: {
    zoom: 2,
    dpi: 300,
    minWidth: 800,
    minHeight: 600
  },
  about: {
    emailTo: 'databases.sys@gmail.com',
    emailSubject: 'About DesignER'
  },
  bugReports: {
    emailTo: 'databases.sys@gmail.com',
    emailSubject: 'DesignER Bug Report'
  },
  analytics: {
    enabled: false,
    apiUrl: '/api/analytics',
    syncPeriodSeconds: 15
  }
};