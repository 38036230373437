<template>
  <DiagramEditor contextName="schemaDesign" :store="store">
    <div class="notes" v-if="!showNotes">
      <div><ToolboxButton text="Notes" icon="into_left" @click="mainStore.toggleNotesDataMenu()" class="notesButton"/></div>
    </div>
    <div class="notes" v-if="showNotes">
      <div><ToolboxButton text="Hide" icon="into_right" @click="mainStore.toggleNotesDataMenu()" class="notesButton" /></div>
      <QuillEditor contextName="schemaDesign"/>
   </div>
    <SchemaDesignEditorToolbox />
    <ZoomButtons contextName="schemaDesign"/>
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
  </DiagramEditor>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import ErrorStatusBar from './ErrorStatusBar';
import SchemaDesignEditorToolbox from './SchemaDesignEditorToolbox';
import ZoomButtons from './ZoomButtons.vue';
import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';
import ToolboxButton from './ToolboxButton.vue';
import QuillEditor from './QuillEditor.vue';

export default {
  name: 'SchemaDesignEditor',
  components: {
    DiagramEditor,
    ErrorStatusBar,
    SchemaDesignEditorToolbox,
    ZoomButtons,
    ToolboxButton,
    QuillEditor,
  },
  setup() {
    const store = mainStore.schemaDesign;    
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    const showNotes = mainStore.$showNotesDataMenu();

    getContext('schemaDesign').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectItem: id => store.selectItem(id),
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem()
    });
   
    return {
      store,
      selectedItemWarnings,
      selectedItemErrors,
      showNotes,
      mainStore,
    };
  }
};
</script>

<style scoped>
.notesButton {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 2em;
  text-align: center;
  visibility: visible; 
}
@media all and (max-width: 720px) {
  .notes {
    display: none;
  }
}
</style>