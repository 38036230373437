<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div v-if="selectedItem">
      <div class="sep"></div>
      <div v-if="supported.unique">
        <ToolboxButton text="Unique" icon="unique" :errorTooltip="allowed.unique.error" :disabled="!allowed.unique.ok" @click="store.toggleUnique()" />
      </div>
      <div class="datatype-container" v-if="supported.dataType">
        <ToolboxButton text="Type" icon="type" :selected="showDataTypeOptions" @click="store.toggleMenu('dataType')" />
        <div class="datatype-select-container" v-if="showDataTypeOptions">
          <select v-focusselect="showDataTypeOptions" @change="store.setType($event.target.value)">
            <option v-for="option in dataTypeOptions" :key="option" :value="option" :selected="selectedItem.getType() === option">{{ option.toUpperCase() }}</option>
          </select>
        </div> 
      </div>
      <div class="submenu-container" v-if="supported.reordering">
        <ToolboxButton text="Move" icon="reorder" :selected="showReorderingMenu" :disabled="!allowed.reorderingMoveUp.ok && !allowed.reorderingMoveDown.ok" @click="store.toggleMenu('reordering')" />
        <div class="submenu-align">
          <div class="submenu" v-if="showReorderingMenu">
            <div><ToolboxButton text="Up" icon="reorder_move_up" :disabled="!allowed.reorderingMoveUp.ok" @click="store.moveColumn(-1)" /></div>
            <div><ToolboxButton text="Down" icon="reorder_move_down" :disabled="!allowed.reorderingMoveDown.ok" @click="store.moveColumn(1)" /></div>
          </div>
        </div>
      </div>
      <div class="sep"></div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
    </div>
    <div v-if="!selectedItem">
      <div class="sep"></div>
      <div><ToolboxButton text="Edit ER" icon="design" @click="mainStore.backToDesign()" /></div>
      <div class="submenu-container">
        <ToolboxButton text="Export" icon="export" :selected="showExportMenu" @click="store.toggleMenu('export')" />
        <div class="submenu-align">
          <div class="submenu" v-if="showExportMenu">
            <div><ToolboxButton text="PNG" icon="image" @click="saveAsPng()" /></div>
            <div><ToolboxButton text="SQL" icon="code" @click="saveAsSql()" /></div>
            <div><ToolboxButton text="Notes" icon="notes" @click="saveAsNotes()" errorTooltip="Notes must be non-empty in order for you to export them" :disabled="!mainStore.getEditorData() || mainStore.getEditorData().html == '' "/></div>
          </div>
        </div>
      </div>
      <ToolboxButton  ref="childComponent" text="Save"  icon="save"  @click="saveToFile()" errorTooltip="The model must be non-empty to be saved" @input-proj="inputProj($event)"/>
      <a ref="erOutputFile"  id="pName" class="hide" :download="setFileName() + '.er'"></a>
      <a ref="outputPngFile" class="hide" :download="setFileName() + '.png'"></a>
      <div ref="outputPngCanvas" class="hide"></div>
      <a ref="outputNotesFile" class="hide" :download="setFileName() + '.txt'"></a>
      <a ref="outputSqlFile" class="hide" :download="setFileName() + '.sql'"></a>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';

import ToolboxButton from './ToolboxButton';

import columnTypes from '../lib/columnTypes';
import { getContext } from '../lib/editor';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import { mainStore } from '../store/main';

export default {
  name: 'SchemaDesignEditorToolbox',
  components: {
    ToolboxButton
  },
  props: ['pName'],
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
      }
    }
  },
  created() {
      window.addEventListener('keydown', this.onKeyDown);        
      window.addEventListener('keypress', this.onKeyPress);
      window.addEventListener('click', this.onClickOver);
      window.addEventListener('dblclick', this.onDoubleClick);
  },
  methods:{
    onClose() {
      this.$emit("dialog-closed");
      this.onClickOver(null);
    },
    onKeyDown(e){
      const step = mainStore.$step();
      if(step.value == 'schemaDesign' && !this.showRenamingInput && this.getSelectedItem()==null){
      if( (e.ctrlKey && e.key == 'p') || (e.ctrlKey  && e.key == 'P') ){
          e.preventDefault();
          this.saveAsPng();
        }
      if( (e.ctrlKey && e.key == 's') || (e.ctrlKey  && e.key == 'S') && !this.empty && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveToFile();
        }
      if( (e.ctrlKey && e.key == 'z') || (e.ctrlKey  && e.key == 'Z') && !this.empty){
          e.preventDefault();
          mainStore.undo();
        }
         if( (e.ctrlKey && e.key == 'y')|| (e.ctrlKey  && e.key == 'Y') && !this.empty){
          e.preventDefault();
          mainStore.redo();
        }
      }
    },
  },
  setup() {
    let currentURL = null;
    const dataTypeOptions = Object.values(columnTypes);
        
    const erOutputFile = ref(null);
    const outputPngFile = ref(null);
    const outputPngCanvas = ref(null);
    const outputSqlFile = ref(null);

    const schemaDesignStore = mainStore.schemaDesign;
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = schemaDesignStore.$selectedItem();
    const showRenamingInput = schemaDesignStore.$renamingInput();
    const showDataTypeOptions = schemaDesignStore.$dataTypeOptions();
    const showReorderingMenu = schemaDesignStore.$reorderingMenu();
    const showExportMenu = schemaDesignStore.$exportMenu();
    
    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const allowed = computed(() => selectedItem.value.getAllowedFunctionalities());
    const pN = inject('pN');
    const outputNotesFile = ref(null);
    function setFileName(){
      if(pN.value.endsWith('_t'))
        return pN.value;
      else if(pN.value.endsWith('_c') || pN.value.endsWith('_r')) 
        return pN.value.slice(0, -2) + '_t';
      else 
        return pN.value + '_t';
    } 
    function saveToFile() {
      if(this.getSelectedItem()==null){
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
      }
    }
    function renameItem(e) {
      schemaDesignStore.renameItem(e.target.value);
      e.target.value = schemaDesignStore.getRenamingValue();
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        schemaDesignStore.toggleMenu();
    }
    async function saveAsPng() {
      if(this.getSelectedItem()==null){
      schemaDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      const context = getContext('schemaDesign');
      currentURL = window.URL.createObjectURL(new Blob([await context.exportPng(outputPngCanvas.value)]));
      outputPngFile.value.href = currentURL;
      outputPngFile.value.click();
      analytics.event(analyticsEvents.LOGICAL_SCHEMA_EXPORT_PNG);
      }
    }
    function saveAsSql() {
      schemaDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([schemaDesignStore.getCode()]));
      outputSqlFile.value.href = currentURL;
      outputSqlFile.value.click();
      analytics.event(analyticsEvents.LOGICAL_SCHEMA_EXPORT_SQL);
    }
    function saveAsNotes() {
      if(mainStore.getEditorData()){
      schemaDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
       //replace(/(<([^>]+)>)/ig, '');      
      currentURL = window.URL.createObjectURL(new Blob([mainStore.getEditorData().replace(/(<([^>]+)>)/ig, '')]));
      outputNotesFile.value.href = currentURL;
      outputNotesFile.value.click();
      }
    }
    function getSelectedItem(){
        return schemaDesignStore.getSelectedItem();
    }
    return {
      store: schemaDesignStore,
      mainStore,
      canUndo,
      canRedo,
      dataTypeOptions,
      outputPngFile,
      outputPngCanvas,
      outputSqlFile,
      selectedItem,
      showRenamingInput,
      showDataTypeOptions,
      showReorderingMenu,
      showExportMenu,
      supported,
      allowed,
      renameItem,
      onRenameKeydown,
      saveAsPng,
      saveAsSql,
      pN,
      saveToFile,
      erOutputFile,
      saveAsNotes,
      outputNotesFile,
      setFileName,
      getSelectedItem
    };
  }
};
</script>